import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class NewProduct extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <section className="featured-area pd-top-90 pd-bottom-100">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-5">
			        <div className="section-title text-center">
			          <h2>Easily integrate with your favorite platforms.</h2>
			          <p></p>
			        </div>
			      </div>
			    </div>
			    <div className="row">
			      <div className="col-lg-12">
			        <ul className="featured-product">
			          <li className="thumb">
			            <Link to="/product-details">
			              <img src={publicUrl+"assets/img/featured/woocommerce.png" }alt="img" />
			            </Link>                            
			          </li>
			          <li className="thumb">
			            <Link to="/product-details">
			              <img src={publicUrl+"assets/img/featured/bigcommerce.png"} alt="img" />
			            </Link>                            
			          </li>
			          <li className="thumb">
			            <Link to="/product-details">
			              <img src={publicUrl+"assets/img/featured/magento.png"} alt="img" />
			            </Link>                            
			          </li>
			          <li className="thumb">
			            <Link to="/product-details">
			              <img src={publicUrl+"assets/img/featured/shopify.png"} alt="img" />
			            </Link>                            
			          </li>
			          <li className="thumb">
			            <Link to="/product-details">
			              <img src={publicUrl+"assets/img/featured/shopify-plus.png"} alt="img" />
			            </Link>                            
			          </li>
			          <li className="thumb">
			            <Link to="/product-details">
			              <img src={publicUrl+"assets/img/featured/6.png"} alt="img" />
			            </Link>                            
			          </li>
			          <li className="thumb">
			            <Link to="/product-details">
			              <img src={publicUrl+"assets/img/featured/weebly.png"} alt="img" />
			            </Link>                            
			          </li>
			        </ul>
			      </div>
			      <div className="col-lg-12 text-center go-top">
			        {/* <Link className="btn btn-base" to="/product">Top New Files</Link>
			        <Link className="btn btn-white" to="/product">All Items</Link> */}
			      </div>
			    </div>
			  </div>
			</section>



        }
}

export default NewProduct