import React from 'react';
import { useRef } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import isAuthenticated from '../../hooks/isAuthenticated';
import Footer from '../global-components/footer-v2';
import NavbarV2 from '../global-components/navbar-v2';
import NavbarMain from '../global-components/NavbarMain';
import Page_header from '../global-components/page-header';

const SignUpUpdate = () => {
	const {isAuthenticate} = isAuthenticated();
    const {user} = isAuthenticate();
	const history = useHistory();

	const bemailRef = useRef();
	const businessRef = useRef();
	const websiteRef = useRef();
	const phoneRef = useRef();
	const pemailRef = useRef();
	const contactRef = useRef();
	const passwordRef = useRef();

	const handleSubmit = async(e) =>{
		e.preventDefault();
		const buinessname = businessRef.current.value;
		const website = websiteRef.current.value;
		const phonenumber = phoneRef.current.value;
		const bemail = bemailRef.current.value;
		const constactperson = contactRef.current.value;
		const pemail = pemailRef.current.value;
		const password = passwordRef.current.value;

		const signUpInfo = {
			buinessname, website, phonenumber, bemail, constactperson, pemail, password 
		}

		console.log(signUpInfo);
		e.preventDefault();
		// if (/^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/.test(bemail)){
		// 	console.log('ok') 
		//   }
		//   else{
		// 	console.log('vul hocche')
		//   }
		  const addRecordEndpoint = "https://trustverified.bookyourdata.de/api/signup";

		  const options = {
				  method: 'POST',
				  headers:{
					  'Content-Type': 'application/json'
				  },
				  body: JSON.stringify(signUpInfo)
			  }
		  
		  const response = await fetch(addRecordEndpoint, options);
		  const jsonResponse = await response.json(); 
		  if(jsonResponse.success){
			history.push("/sign-in");
		  }
		 alert(jsonResponse.message)
	}
	
    return (
        <>
		 <NavbarMain />
		 {
			!user ? <> <Page_header headertitle="Become a Member"  />
           <section className="pd-top-100 pd-bottom-100 bg-sky-blue">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-xl-7 col-lg-10 text-center">
			        <div className="sign-in-area">
			          <h2>Sign up to your account</h2>
			          <form className="contact-form-wrap" onSubmit={handleSubmit}>
					  <div className="single-input-wrap input-group">
			              <label htmlFor="inp-0">BUSINESS NAME</label>
			              <input id="inp-0" ref={businessRef} type="text" className="form-control" placeholder="Enter your Name"/>
			            </div>
						<div className="single-input-wrap input-group">
			              <label htmlFor="inp-0">WEBSITE URL</label>
			              <input id="inp-0" type="text" ref={websiteRef}  className="form-control" placeholder="Enter your Name" htmlFor="email" />
			            </div>
						
			            <div className="single-input-wrap input-group">
			              <label htmlFor="inp-1">BUSINESS EMAIL</label>
			              <input id="inp-1"  ref={bemailRef} type="email" className="form-control" placeholder="Enter your email address" />
			            </div>
						<div className="single-input-wrap input-group">
			              <label htmlFor="inp-1">BUSINESS PHONE</label>
			              <input id="inp-1" type="text" ref={phoneRef} className="form-control" placeholder="Enter your email address" />
			            </div>
						<div className="single-input-wrap input-group">
			              <label htmlFor="inp-0">CONTACT PERSON</label>
			              <input id="inp-0" type="text" ref={contactRef} className="form-control" placeholder="Enter your Name" />
			            </div>
						<div className="single-input-wrap input-group">
			              <label htmlFor="inp-0">CONTACT PERSON EMAIL</label>
			              <input id="inp-0" type="email" ref={pemailRef} className="form-control" placeholder="Enter your Name" />
			            </div>
			            <div className="single-input-wrap input-group">
			              <label htmlFor="inp-2">CREATE A PASSWORD</label>
			              <input id="inp-2" type="password" ref={passwordRef} className="form-control" placeholder="Enter your email password" />
			            </div>
						<div className="row">
			              <div className="col-lg-6 col-sm-6 col-12">
			                <div className="single-category text-left">
			                  <label><input type="checkbox"  aria-label="Checkbox for following text input" />
			                    <span className="checkmark" />
			                    Remember me</label>
			                </div>
			              </div>
			              <div className="col-lg-6 col-sm-6 col-12 text-sm-right text-left">
			                <a className="forget-pass" href="#">Forgot your password?</a>
			              </div>
			            </div>
			            <button className="btn btn-base w-100">Sign Up</button>
			            {/* <p>Or continue with</p>
			            <button className="btn btn-g w-100"><i className="fab fa-google" />Continue with Google</button>
			            <button className="btn btn-f w-100"><i className="fab fa-facebook-f" />Continue with Facebook</button>*/}
			            <p>Already Have account? <Link to="/sign-in">Sign In</Link></p> 
			          </form>
			        </div>
			      </div>  
			    </div>
			  </div>          
			</section> 
			<Footer></Footer></>: <Redirect to="/"></Redirect> 
		 }
        </>
    );
};

export default SignUpUpdate;