import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class PlanPricing extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <section className="policy-page-area pd-top-100 pd-bottom-100">
			<div className="container">
				<div className="row">
					<table class="table table-striped">
						<thead>
							<tr>
							<th scope="col">Benefits</th>
							<th scope="col">Intro</th>
							<th scope="col">Bronze</th>
							<th scope="col">Silver</th>
							</tr>
						</thead>
						<tbody>
						<tr>
							<th scope="row">Member of the Trust-Verified  <br></br> Seal of Approval network.</th>
							<td>Introductory</td>
							<td>Full</td>
							<td>Full</td>
						</tr>
						<tr>
							<th scope="row">Use of Trust-Verified Seal</th>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Choice of Seal Design</th>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Choice of Seal display options</th>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Business Details on <br/>Trust-Verified website</th>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Initial customer rating and <br/> review survey (at time of sales)</th>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Optional full survey after<br/> a customer receives their order</th>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Access to Members-Only Area</th>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Customer Feedback – 5 star rating</th>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>

						<tr>
							<th scope="row">Customer Comments displayed</th>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Verified phone</th>
							<td></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Verified address</th>
							<td></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Check that Terms & <br/>Conditions are displayed</th>
							<td></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Check that Privacy Policy is<br/> displayed</th>
							<td></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Affiliate tracking & payments</th>
							<td></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Ability to respond to<br/>customer comments</th>
							<td></td>
							<td></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Optional monthly website check</th>
							<td></td>
							<td></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Multiple websites</th>
							<td></td>
							<td></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Multiple pages (on<br/> our website)</th>
							<td></td>
							<td></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Optional complaints recording</th>
							<td></td>
							<td></td>
							<td><img src={publicUrl+"assets/img/icon/util.png"}/></td>
						</tr>
						<tr>
							<th scope="row">Cost($USD)</th>
							<td><Link to="signup"><button className='btn-success' style={{padding:"5px 20px", borderRadius: "10px"}}>Order</button></Link></td>
							<td><Link to="signup"><button className='btn-success' style={{padding:"5px 20px", borderRadius: "10px"}}>Order</button></Link></td>
							<td><Link to="signup"><button className='btn-success' style={{padding:"5px 20px", borderRadius: "10px"}}>Order</button></Link></td>
						</tr>
					</tbody>
					</table>
					
				</div>
				<Link to="/signup">
					<div className="row border border-primary">
						<div className="col-md-4">
							<div className="free-content">
								<h3>Free</h3>
								<p>Perosnal or hobby Project</p>
								 <h5>Support</h5>
								 <p>Community and developer </p>
							</div>
						</div>
						<div className="col-md-4">
							<div className="free-content">
								<h3>Free</h3>
								<p>Use of Trust-Verified Seal</p>
								<p>Access to Members-Only Area</p>
								<p>Free Support</p>
							</div>
						</div>
						<div className="col-md-4">
							<div className="free-content">
								<h3>Free</h3>
								<p>Use of Trust-Verified Seal</p>
								<p>Perosnal or hobby Project</p>
								<p>Community and developer </p>
							</div>
						</div>	
					</div>
				</Link>
			</div>
		</section>
	}
}

export default PlanPricing