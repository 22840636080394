import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class BenifiTrust extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <section className="policy-page-area pd-top-100 pd-bottom-100">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-8">
						<h4>Benefits of a Trust Seal:</h4>
						<p>The benefits of a good Trust seal include:</p>
						<ul>
							<li>Third party validation of your website</li>
							<li>Instill confidence in your visitors</li>
							<li>Increase conversion rates</li>
							<li>Increase sales and profit</li>
							<li></li>
							<li>Demonstrate commitment to customer service</li>
							<li>Stand out from the crowd, compete with the big brands</li>
							<li>Collect and respond to genuine customer feedback</li>
						</ul>
						<h4>Benefits of Trust-Verified seal program:</h4>
						<ul>
							<li>3 month free trial</li>
							<li>Proven and effective Trust Seal</li>
							<li>Business Verification Page, to increase visitor confidence</li>
							<li>Collect and respond to customer feedback</li>
							<li></li>
							<li>Online account administration</li>
						</ul>
						<h4>MEMBERSHIP REQUIREMENTS for Trust-Verified:</h4>
						<ol>
							<li>Pass a background check for a history of customer complaints.</li>
							<li>Website must be a registered top level domain or be part of a shopping mall, auction block, or blog network.</li>
							<li>A form of communication posted on your website, such as an email, contact form, fax, or phone number.</li>
							<li>Provide a public contact email address to Trust-Verified for any consumers contacting Trust-Verified.</li>
							<li>Provide a private contact email for your company. This email will remain completely confidential with Trust-Verified. It may be the same as your public email address.</li>
							<li>Bronze Membership also requires verification of your physical address and phone number, privacy policy, terms and conditions.</li>
						</ol>
					</div>
				</div>
			</div>
		</section>
	}
}

export default BenifiTrust