import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import FaqPage from './section-components/faq';
import Footer from './global-components/footer-v2';
import Policy from './section-components/policy';
import TrustSeal1 from './section-components/howtrustseal';
import Certifications from './section-components/certifications';
import NavbarMain from './global-components/NavbarMain';

const Cirtificate = () => {
    return <div>
        <NavbarMain/>
            <PageHeader headertitle="Grow visitor confidence." subheader="Grow visitor confidence."  />
            <Certifications/>
        <Footer />
    </div>
}

export default Cirtificate

