import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ExampleCom extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <section className="policy-page-area pd-top-100 pd-bottom-100">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-8">
						<h4>The Importance of Website Credibility:</h4>
						<hr/>
						<h6>Why does Web credibility matter?:</h6>
						<ul>
							<li>Web presence is important for most businesses</li>
							<li>Web information has mixed credibility – some parts of it are the least credible and other parts are the most credible</li>
							<li>For many potential customers, the website is the first point of contact with a business</li>
							<li>The Web is here to stay, and becoming increasingly influential</li>
							<li>Credibility is a very valuable asset for a company</li>
							<li>Web users can be naïve or lazy</li>
							<li>Those businesses with a more credible web presence gain a strategic advantage.</li>
						</ul>
						<h6>Credibility Gives You the Power To –</h6>
						<ul>
							<li>Change user’s attitudes eg
								<ul>
									<li>To think more positively about the site owner</li>
									<li>To feel comfortable interacting with the site</li>
									<li>To embrace the site’s point of view</li>
								</ul>
							</li>
							<li>Change user’s behaviours eg
								<ul>
									<li>To register personal information</li>
									<li>To complete e-commerce purchases</li>
									<li>To return to the site more often</li>
								</ul>
							</li>
						</ul>
						<h6>What creates Credibility?</h6>
						<ul>
							<li>Credibility is a perception based on two main factors
								<ul>
									<li>Trustworthiness (eg unbiased, truthful, good, honest)</li>
									<li>Expertise (eg experience, intelligent, knowledgeable, powerful)</li>
								</ul>
							</li>
						</ul>
						<h6>Trustworthiness + Expertise = Credibility</h6>
						<p>Both factors are essential for credibility – the absence of either factor will reduce credibility.</p>
						<Link to="">Guidelines for creating credible websites.</Link>
					</div>
				</div>
			</div>
		</section>
	}
}

export default ExampleCom