import React from 'react';
import { API } from '../config';

const useAuthuser = () => {
    const signIn = user =>{
        return fetch (`${API}/login`,{
            method:"POST",
            headers:{
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        })
        .then(res=>{
            return res.json();
        })
        .catch(err=>{
            console.log(err)
        })
     }
     const authenticate = (data, next) =>{
        if(typeof window !== "undefined"){
           localStorage.setItem("trust_jwt_token", JSON.stringify(data))
           next(); 
        }
     }

     const signOut = (next)=>{
        if(typeof window!== "undefined"){
            localStorage.removeItem('trust_jwt_token')
            next();
            return fetch(`${API}/logout`,{
             method: "GET"
            })
            .then(res=> {
             console.log('signout', res)
            })
            .catch(err=>{
              console.log(err)
            })
         }
     }
    
   return {signIn, authenticate, signOut}
};

export default useAuthuser;