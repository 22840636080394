import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class WebsiteImportance extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <section className="policy-page-area pd-top-100 pd-bottom-100">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-8">
						<p>Trust seals refer to images used on websites to increase visitor confidence, helping them proceed with the purchasing process and increasing website sales.</p>
						<p>There are a wide variety of seals available, including business validity seals, PCI compliance seals, privacy seals, hacker-safe seals and others.</p>
						<p>Having no trust seal on your website will usually hurt your website sales. Having multiple trust seals can also hurt. Independent research shows that the use of multiple seals can actually have a negative effect and can decrease visitor confidence. [ref]The effects of Web assurance seals on consumers’ initial trust in an online vendor: A functional perspective by Xiaorui Hu, Guohua Wu, Yuhong Wu, Han Zhang 2007[/ref] [ref]The Value of Online Trust Seals: Evidence from Online Retailing by Koray Özpolat, Guodong (Gordon) Gao, Wolfgang Jank and Siva Viswanathan[/ref] [ref]Assurance Seals: Security and Privacy Seals by Jing Cui[/ref]. Hence we recommend the use of only one business verification seal (that covers multiple areas) on your website, rather than confusing visitors with multiple seals.</p>
						<p>Wise website designers and owners use a trust seal from a trustworthy independent verification company (eg Trust-Verify) to help increase their visitors confidence, helping make them feel more comfortable about the honesty, safety, privacy and confidentiality of the site they are visiting. As a general rule, wise use of a valid trust seal can increase the conversion rate of a website, typically by any amount from 2% to 50%. One large study found that the presence of a trust seal on a website increased the income for online retailers by an average of 10.25%, and was especially effective for smaller retailers.[ref]The Value of Online Trust Seals: Evidence from Online Retailing by Koray Özpolat, Guodong (Gordon) Gao, Wolfgang Jank and Siva Viswanathan[/ref]</p>
						<p>Trust Seals usually have a positive effect on conversion rate – but the size of that effect will vary depending on the nature of the site and products being sold, the design of the site, the perceived trustworthiness of the business, what seal is being used, where it is placed etc. There is good evidence that the use of a trust seal linked to genuine and positive customer feedback is more effective than a general trust seal which has no capacity for showing customer feedback.</p>
						<p>With the use of a trust seal, it is important to test the effect of the seal, along with variations on the size and placement of the seal. If a trust seal is used properly, the effect on conversion rate should be positive or neutral. If the trust seal is improperly used, such as using a fake seal or having the click-through not working, then the conversion rate is likely to suffer.</p>
						<h4>Here are some suggestions regarding the use of a trust seal:</h4>
						<p></p>
						<ul>
							<li>Set up a good method for split A/B testing on your website if possible</li>
							<li>Start testing with a respected seal, and preferably one that gives a free trial period</li>
							<li>A good location to place a seal is near the logo (top left) or at the top right corner of the site</li>
							<li>Display the seal near the main Call to-Action button, and also in the checkout process</li>
							<li>Place the seal above the fold, and if your text is much longer, repeat the seal near your call to action</li>
							<li>A click on the seal should open the merchant site in a new window, and display accurate information about your business, and also preferably feedback from your customers</li>
							<li>The seal should generally be displayed on all pages of your site, and in the same position on these pages. However, you might choose to use a different seal image size and location on your checkout pages</li>
							<li>After placing the seal, you should also make sure that your site visitors are educated about the seal, so that the positive effect of the seal can be maximized</li>
						</ul>
						
					</div>
				</div>
			</div>
		</section>
	}
}

export default WebsiteImportance