import React from 'react';
import { useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import Footer_v2 from '../global-components/footer-v2';
import Navbar from '../global-components/navbar-v2';
import Page_header from '../global-components/page-header';
import useAuthuser from '../../hooks/useAuthuser';
import NavbarMain from '../global-components/NavbarMain';
import isAuthenticated from '../../hooks/isAuthenticated';

const SignInUpdate = () => {
    const {isAuthenticate} = isAuthenticated();
    const {user} = isAuthenticate();
    const {signIn, authenticate} = useAuthuser();
    const history = useHistory();
    const [values, setValues] = useState({
        pemail:"", 
        password:"",
        error:"",
        loading:"",
        redirectToReferrer: false,
    })
    const {pemail, password, error, loading, redirectToReferrer} = values;

    const handleLogin = e =>{
        e.preventDefault();
        setValues({...values, error:false, loading: true})
        signIn({pemail, password}).then(data=>{
            if(data.success == false){
                alert(data.message)
            } else{
                authenticate(data, ()=>{
                    setValues({
                        ...values, redirectToReferrer: true
                    })
                    alert(data.message);
                    history.push('/')
                })
            }
        })
    }

    const handleChange = name => e =>{
        setValues({...values, error: false, [name]: e.target.value})
    }




    let publicUrl = process.env.PUBLIC_URL+'/'
    return (
        <>  <NavbarMain />
          <Page_header headertitle="Sign In" />
  {
          !user ? <> 
         <section className="pd-top-100 pd-bottom-100 bg-sky-blue">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-10 text-center">
                <div className="sign-in-area">
                  <h2>Sign in to your account</h2>
                  <form className="contact-form-wrap" onSubmit={handleLogin}>
                    <div className="single-input-wrap input-group">
                      <label htmlFor="inp-1">Email address</label>
                      <input id="inp-1" type="text" onChange={handleChange('pemail')} className="form-control" placeholder="Enter your email address" required />
                    </div>
                    <div className="single-input-wrap input-group">
                      <label htmlFor="inp-2">Password</label>
                      <input id="inp-2" type="password" onChange={handleChange('password')} className="form-control" placeholder="Enter your email password" required/>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-sm-6 col-12">
                        <div className="single-category text-left">
                          <label><input type="checkbox" aria-label="Checkbox for following text input" />
                            <span className="checkmark" />
                            Remember me</label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-12 text-sm-right text-left">
                        <a className="forget-pass" href="#">Forgot your password?</a>
                      </div>
                    </div>
                    <button className="btn btn-base w-100">Sign In</button>
                    <p>Or continue with</p>
                    <button className="btn btn-g w-100"><i className="fab fa-google" />Continue with Google</button>
                    <button className="btn btn-f w-100"><i className="fab fa-facebook-f" />Continue with Facebook</button>
                    <p>Have you didn't any account? <Link to="/signup">Sign Up</Link></p>
                  </form>
                </div>
              </div>  
            </div>
          </div>          
        </section>
              <Footer_v2 /></> : <Redirect to="/" />
        }
       
        </>
    );
};

export default SignInUpdate;