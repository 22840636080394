import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ProductV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return   <section className="all-item-area all-item-area-2 pd-top-237 pd-bottom-100">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-4">
			        <div className="section-title">
			          <h2>Featured Items</h2>
			        </div>
			      </div>
			      <div className="col-lg-8">
			        <div className="isotope-filters item-isotope-btn text-lg-right">
			          
			          <button className="button" data-filter=".cat-1">How it Works</button>
			          <button className="button" data-filter=".cat-2">Sample</button>
			          <button className="button" data-filter=".cat-3">More Information</button>
			        </div>
			      </div>
			    </div>
			    <div className="all-item-section">        
			      <div className="item-isotope row">
			        <div className="item-sizer col-1" />
			        {/* gallery item start here */}
			        <div className="all-isotope-item col-lg-6 col-sm-6 cat-1">
			           <h4>Two main aims </h4>
					   <div className="faq-accordion accordion" id="accordionExample">
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn" type="button" data-toggle="collapse" data-target="#collapseOne">
			                  Increase Sales
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseOne" className="collapse show" data-parent="#accordionExample">
			              <div className="card-body">
						  Display a Trust Seal on your website, to increase visitor’s confidence & increase sales.
			              </div>
			            </div>
			          </div>                      
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
			                   Collect Feedback
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseTwo" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
						  Collect & display feedback from your customers.
			              </div>
			            </div>
			          </div>
			        </div>
			        </div>
			        <div className="all-isotope-item col-lg-6 col-sm-6 cat-1">
					<div className="faq-accordion accordion" id="accordionExample">
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn" type="button" data-toggle="collapse" data-target="#collapseOne">
			                  What Payment Methods Are Accepted?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseOne" className="collapse show" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>                      
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
			                  Can I offer my items for free on a promotional basis?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseTwo" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree">
			                  How Do I Track My Order?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseThree" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour">
			                  How Can | Return a Product?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseFour" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFive">
			                  What Shipping Methods Are Available?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseFive" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSix">
			                  What Shipping Methods Are Available?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseSix" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven">
			                  How do I become an author?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseSeven" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseEight">
			                  Tips for Increasing Your Referral Income
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseEight" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			        </div>
			        </div>
			        <div className="all-isotope-item col-lg-6 col-sm-6 cat-3 cat-2">
					<div className="faq-accordion accordion" id="accordionExample">
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn" type="button" data-toggle="collapse" data-target="#collapseOne">
			                  What Payment Methods Are Accepted?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseOne" className="collapse show" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>                      
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
			                  Can I offer my items for free on a promotional basis?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseTwo" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree">
			                  How Do I Track My Order?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseThree" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour">
			                  How Can | Return a Product?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseFour" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFive">
			                  What Shipping Methods Are Available?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseFive" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSix">
			                  What Shipping Methods Are Available?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseSix" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven">
			                  How do I become an author?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseSeven" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseEight">
			                  Tips for Increasing Your Referral Income
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseEight" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			                consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
			              </div>
			            </div>
			          </div>
			        </div>
			        </div>
			      </div>
			    </div>
			    <div className="row">
			     
			    </div>
			  </div>        
			</section>


        }
}

export default ProductV2