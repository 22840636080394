import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter, Route, Router, Routes, Switch } from "react-router-dom";
import HomeV1 from './components/home-v1';
import HomeV2 from './components/home-v2';
import Product from './components/product'
import ProductDetails from './components/product-details';
import Vendor from './components/vendor';
import Category from './components/category';
import Faq from './components/faq';
import policy from './components/policy';
import Error from './components/error';
import SignIn from './components/sign-in';
import SignUp from './components/sign-up';
import Contact from './components/contact';
import Blog from './components/blog';
import BlogDetails from './components/blog-details';
import ProductV2 from './components/section-components/product-v2';
import UseTrustSeal from './components/usetrustseal';
import BenifiTrust from './components/section-components/benifittrust';
import BenefitsTrustSeal from './components/benfittrustseal';
import ExampleCompany from './components/examcompany';
import WebImportance from './components/webimportance';
import ConsumerBenift from './components/consumerbenifit';
import CaseStudies from './components/section-components/casestudies';
import CaseStudy from './components/casestudies';
import Cirtificate from './components/cirtificate';
import Pricing from './components/section-components/pricing';
import PricingTable from './components/pricingtable';
import SignUpUpdate from './components/section-components/SignUpUpdate';
import SignInUpdate from './components/section-components/SignInUpdate';
import PrivateRoute from './components/PrivateRoute';



class Root extends Component {
    render() {
        return( 
			<BrowserRouter basename="/">
			<div>
			<Switch>
				<Route exact path="/" component={HomeV2} />
				<Route  path="/home-v2" component={HomeV2} />
				<Route  path="/product" component={Product} />
				<Route  path="/product-details" component={ProductDetails} />
				<Route  path="/vendor" component={Vendor} />
				<Route  path="/category" component={Category} />
				<Route  path="/faq" component={Faq} />
				<Route  path="/policy" component={policy} />
				<Route  path="/error" component={Error} />
				{/* <Route  path="/sign-in" component={SignIn} /> */}
				<Route  path="/sign-up" component={SignUp} />
			
				<Route path = "/sign-in" component={SignInUpdate}/>
				<Route path = "/signup" component={SignUpUpdate}/>
				<Route  path="/contact" component={Contact} />
				<Route path="/blog" component={Blog} />
				<Route path="/blog-details" component={BlogDetails} />
				<Route path="/how-to-use-trust-seal" component={UseTrustSeal} />
				<Route path="/benefits-of-a-trust-seal" component = {BenefitsTrustSeal}/>
				<Route path="/example-company" component = {ExampleCompany}/>
				<Route path="/consumer-benefits" component = {ConsumerBenift}/>
				<Route path="/case-studies" component = {CaseStudy}/>
				<Route path="/the-importance-of-website-credibility" component={WebImportance}/>
				<Route path="/certifications" component = {Cirtificate}/>
				<Route path="/pricingtable" component={PricingTable}/>
		
				
			</Switch>
			</div>
		</BrowserRouter>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('drketa'));
