import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import useAuthenticated from '../../hooks/useAuthuser'

class NavbarV2 extends Component {

    render() {
		
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
        let anchor = '#'
		
        return (
        	<div>
        		<div className="dkt-sitebar-menu">
				  <div className="dkt-sitebar-menu">
				    <span className="dkt-sitebar-close" ><i className="fa fa-times" /></span>
				    <div className="dkt-details-inner">
				      <div className="logo go-top">
				        <Link to="/"><img src={publicUrl+"assets/img/logo-green.png"} alt="img" /></Link>
				      </div>
				      <p className="details">Donsectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. </p>
				      <div className="address-inner">
				        <h5>Address</h5>
				        <p>3538 Cambridge Place Laurel, MD 20707</p>
				      </div>
				      <div className="address-inner">
				        <h5>Phone</h5>
				        <p>410-565-2575</p>
				      </div>
				      <div className="address-inner mb-0">
				        <h5>Email</h5>
				        <p>support@trustverifed.org</p>
				      </div>
				    </div>
				    <div className="dkt-market-earn">
				      <div className="address-inner">
				        <h5>Market Earning</h5>
				        <p>online store with lots of digital product and exclusive Item</p>
				      </div>
				      <div className="row">
				        <div className="col-lg-6">
				          <div className="earn-inner">
				            <p>Item Sold</p>
				            <h5>12501</h5>
				          </div>
				        </div>
				        <div className="col-lg-6">
				          <div className="earn-inner">
				            <p>Total Earning</p>
				            <h5>25804</h5>
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
	            <div className="navbar-area navbar-area-2 go-top">
				  <nav className="navbar navbar-expand-lg">
				    <div className="container nav-container">
				      <div className="responsive-mobile-menu">
				        <button className="menu toggle-btn d-block d-lg-none" data-target="#dkt_main_menu" aria-expanded="false" aria-label="Toggle navigation">
				          <span className="icon-left" />
				          <span className="icon-right" />
				        </button>
				      </div>
				      <div className="logo">
				        <Link className="main-logo" to="/"><img src={publicUrl+"assets/img/logo-green.png"} alt="img" /></Link>
				      </div>
				      <div className="nav-right-part nav-right-part-mobile">
				        <a className="btn btn-base" href="#">Sign in</a>
				      </div>
				      <div className="collapse navbar-collapse" id="dkt_main_menu">
				        <ul className="navbar-nav menu-open">
				          {/* <li className="menu-item-has-children current-menu-item">
				            <a href="#">Home</a>
				            <ul className="sub-menu">
				              <li><Link to="/">Home 01</Link></li>
				              <li><Link to="/home-v2">Home 02</Link></li>
				            </ul>
				          </li> */}
						  <li><Link to="/">Home</Link></li>
				     
						  <li className="menu-item-has-children current-menu-item">
				            <a href="#">Product </a>
				            <ul className="sub-menu">
								
				              <li><Link to="/certifications">Certifications</Link></li>
				              <li><Link to="/">Trustmarks</Link></li>
				              <li><Link to="/vendor">Customer Results</Link></li>
				              <li><Link to="/pricingtable">Plans & Pricing</Link></li>
				              {/* <li><Link to="/faq">Faq</Link></li>
				              <li><Link to="/policy">Refund Policy</Link></li>
				              <li><Link to="/error">404</Link></li>
				              <li><Link to="/sign-in">Sign In</Link></li>
				              <li><Link to="/sign-up">Sign Up</Link></li> */}
				            </ul>
				          </li>
				          <li className="menu-item-has-children current-menu-item">
				            <a href="#">Infromation</a>
				            <ul className="sub-menu">
				              <li><Link to="/how-to-use-trust-seal">How to Use a Trust Seal</Link></li>
				              <li><Link to="/benefits-of-a-trust-seal">Benefits of a Trust Seal</Link></li>
				              <li><Link to="/example-company">Example Company</Link></li>
				              <li><Link to="/the-importance-of-website-credibility">The Importance of Website Credibility</Link></li>
				              <li><Link to="/faq">Building Website Credibility</Link></li>
				              <li><Link to="/case-studies">Case Studies</Link></li>
							  <li><Link to="/consumer-benefits">Consumer Benefits</Link></li>
				              {/* <li><Link to="/sign-in">Consumer Benefits</Link></li>
				              <li><Link to="/sign-up">Sign Up</Link></li> */}
				            </ul>
				          </li>
				          <li className="menu-item-has-children current-menu-item">
				           <Link to="/blog">Resources</Link>
				            <ul className="sub-menu">
				              {/* <li><Link to="/blog">Consumer Trust</Link></li> */}
				              <li><Link to="/blog">Blog</Link></li>
							  {/* <li><Link to="">Additional Resources</Link></li> */}
				            </ul>
				          </li>
						  <li><Link to="/">Overview</Link></li>
				          {/* <li><Link to="/contact">Contact</Link></li> */}
				        </ul>
				      </div>
				      <div className="nav-right-part nav-right-part-desktop">
				        <ul>
				          <li><a className="search" href="#"><img src={publicUrl+"assets/img/icon/1b.png"} alt="icon" /></a></li>
				          <li className="menu-bar dropdown-menu-btn"><i className="fa fa-user" /></li>
						  {/* <li className="menu-bar dropdown-menu-btn"><i className="fa fa-user" /></li> */}
						  <Link className="custom" style={{padding:"-50px 20px!important"}} to="/sign-in">Get Certified</Link>
				        </ul>
				      </div>
				    </div>
				  </nav>
				</div>
			</div>


        )
    }
}


export default NavbarV2