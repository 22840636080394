import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import FaqPage from './section-components/faq';
import Footer from './global-components/footer';
import Policy from './section-components/policy';
import TrustSeal1 from './section-components/howtrustseal';
import NavbarMain from './global-components/NavbarMain';

const UseTrustSeal = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="How to Use a Trust Seal" subheader="How to Use a Trust Seal"  />
         <TrustSeal1/>
        <Footer />
    </div>
}

export default UseTrustSeal

