import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import FaqPage from './section-components/faq';
import Footer from './global-components/footer-v2';
import Policy from './section-components/policy';
import TrustSeal1 from './section-components/howtrustseal';
import WebsiteImportance from './section-components/websiteimport';
import ConsumerBenifts from './section-components/benifitconsumer';
import BenfitConsumers from './section-components/benifitconsumer';
import PlanPricing from './section-components/planpricing';
import NavbarMain from './global-components/NavbarMain';

const PricingTable = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Plans & Pricing" subheader="Plans & Pricing"  />
        <PlanPricing/>
        <Footer />
    </div>
}

export default PricingTable

