import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Faq extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <section className="faq-page-area pd-top-100 pd-bottom-100">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-12">
			        <div className="faq-accordion accordion" id="accordionExample">
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn" type="button" data-toggle="collapse" data-target="#collapseOne">
							What’s a visit?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseOne" className="collapse show" data-parent="#accordionExample">
			              <div className="card-body">
						  Visits represent unique 24-hour sessions that a site gets during a month. Any activity on a device within 24 hours is counted as the same visit.
			              </div>
			            </div>
			          </div>                      
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
							What does the free plan include?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseTwo" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
						  Our free plans include displaying the floating trustmark for up to 500 visits per month. The trustmark will disappear after the limit is reached and resets at the beginning of each month.
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree">
							What if I have more visits than included in my plan?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseThree" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
						  If you're nearing your included visit limit, we'll send several emails to notify you so you can move into a correctly sized plan. If you have traffic spikes, we'll simply charge you for each additional 1,000 visits your site receives in the month, always rounding down to the nearest thousand.
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour">
							Can I change my plan later?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseFour" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
						  Absolutely. Simply contact us and we'll adjust your account to the correct plan for your business.
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFive">
							I get more than 1 millions visits per month.
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseFive" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
						  Please contact us and we'll find a plan that suits your needs.
			              </div>
			            </div>
			          </div>
			          <div className="card">
			            <div className="card-header">
			              <h2>
			                <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSix">
							I have more questions. Can you help?
			                  <span className="collapse-icon" />
			                </button>
			              </h2>
			            </div>
			            <div id="collapseSix" className="collapse" data-parent="#accordionExample">
			              <div className="card-body">
			              We're here to help. Just get in touch and we're happy to answer any questions you have.
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>  
			    </div>
			  </div>          
			</section>
        }
}

export default Faq