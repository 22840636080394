import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import FaqPage from './section-components/faq';
import Footer from './global-components/footer';
import Policy from './section-components/policy';
import TrustSeal1 from './section-components/howtrustseal';
import WebsiteImportance from './section-components/websiteimport';
import NavbarMain from './global-components/NavbarMain';

const WebImportance = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="The Importance of Website Credibility" subheader="The Importance of Website Credibility"  />
        <WebsiteImportance/>
        <Footer />
    </div>
}

export default WebImportance

