import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class BenfitConsumers extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <section className="policy-page-area pd-top-100 pd-bottom-100">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-8">
						<h4>Increased Confidence in Trust-Verified Companies:</h4>
						<p>Potential website customers can have increased confidence in Trust-Verified (T-V) members.</p>
						<p>We provide services which benefit potential website customers, including:</p>
						<ul>
							<li>Genuine third party validation of the business and website – we assess them, so you can trust them</li>
							<li>Checking of business details including email address, phone number, business address, privacy policy</li>
							<li>Checking of website including display of Terms and Conditions, and Privacy Policy</li>
							<li>Surveys of previous customers, both at the time of purchase and also with a follow-up survey a few weeks later</li>
							<li>A 5 star rating system based on customer feedback</li>
							<li>A 5 star rating system based on customer feedback
Collection and display of feedback and comments from previous customers</li>
							<li>Display of the business response to any suggestions or negative feedback received from customers</li>
							<li>By joining T-V, businesses demonstrate that they are committed to customer service, honesty, transparency and accountability</li>
							<li>T-V business members stand out from the crowd – so you can , compete with the big brands</li>
							<li>Collect and respond to genuine customer feedback</li>
						</ul>
						<h4>To Become a Member of Trust-Verified, the Business MUST:</h4>
						<ul>
							<li>Pass a background check for a history of customer complaints.</li>
							<li>The website must be a registered top level domain or be part of a shopping mall, auction block, or blog network.</li>
							<li>Provide a reliable form of communication on their website, such as an email, contact form, fax, or phone number.</li>
							<li>Provide a public contact email address to Trust-Verified for any consumers contacting Trust-Verified</li>
							<li>Bronze Membership & higher also require verification of the company’s physical address and phone number, privacy policy, terms and conditions.</li>
						</ul>
						<h4>WE assess them, so YOU can trust them</h4>
						
					</div>
				</div>
			</div>
		</section>
	}
}

export default BenfitConsumers