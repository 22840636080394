import React from 'react';

const isAuthenticated = () => {
    const isAuthenticate = ()=>{
        if(typeof window == 'undefined' ){
            return false;
        } 
        if (localStorage.getItem('trust_jwt_token')){
            return JSON.parse(localStorage.getItem('trust_jwt_token'))
        } else{
            return false;
        }
     }
     return {isAuthenticate}
};

export default isAuthenticated;