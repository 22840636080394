import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Certifications extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <section className="policy-page-area pt-4 pd-bottom-100">
			<div className="container">
				<div className="row gx-5">
					<div className="col-lg-6 col-md-6 col-sm-12 text-center">
						<div className="card card1" style={{marginTop:"-60px"}}>
							<div className="card-body">
								<img src="/assets/img/icon/lock.svg" alt="" className=''/>
								<h4 className="card-title">Certified Secure</h4>
								<p className="card-text">Address visitor concerns about malware, viruses, and phishing and show your site's secure.</p>
								<a href="#" className="card-link ts-button grey">Learn More</a>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12 text-center">
						<div className="card card1" style={{width: "", marginTop:"-60px"}}>
							<div className="card-body">
								<img src="/assets/img/icon/business.svg" alt="" />
								<h4 className="card-title">Verified Business</h4>
								<p className="card-text">Show your site is a reliable business that visitors can easily connect with.</p>
								<a href="#" className="card-link ts-button grey">Learn More</a>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12 text-center mt-4">
						<div className="card card1">
							<div className="card-body">
								<img src="/assets/img/icon/cart.svg" alt="" />
								<h4 className="card-title">Issue-Free Orders</h4>
								<p className="card-text">Show your site is a reliable business that visitors can easily connect with.</p>
								<a href="#" className="card-link ts-button grey">Learn More</a>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12 text-center mt-4">
						<div className="card card1">
							<div className="card-body">
								<img src="/assets/img/icon/fingerprint.svg" alt="" />
								<h4 className="card-title">Shopper Identity Protection</h4>
								<p className="card-text">Show your site is a reliable business that visitors can easily connect with.</p>
								<a href="#" className="card-link ts-button grey">Learn More</a>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 text-center mt-4">
						<div className="card card1">
							<div className="card-body">
								<img src="/assets/img/icon/fingerprint.svg" alt="" />
								<h4 className="card-title">Spam-Free</h4>
								<p className="card-text">Alleviate concerns about spam and collect more registrations.</p>
								<a href="#" className="card-link ts-button grey">Learn More</a>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 text-center mt-4">
						<div className="card card1">
							<div className="card-body">
								<img src="/assets/img/icon/star.svg" alt="" />
								<h4 className="card-title">Trusted Reviews</h4>
								<p className="card-text">Show that people love your business with authentic reviews.</p>
								<a href="#" className="card-link ts-button grey">Learn More</a>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 text-center mt-4">
						<div className="card card1">
							<div className="card-body">
								<img src="/assets/img/icon/user-lock.svg" alt="" />
								<h4 className="card-title">Data Protection</h4>
								<p className="card-text">Keep customer data safe with SSL encryption.</p>
								<a href="#" className="card-link ts-button grey">Learn More</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	}
}

export default Certifications