import React from 'react';

const BrandingSection = () => {
    let publicUrl = process.env.PUBLIC_URL + '/'

    return (
        <>
            <section className='branding-section pd-top-100 pd-bottom-70'>
                <div className="container">
                    <p className='text-center small black bold'>Increasing trust on over 100,000 websites</p>
                    <div className="row">
                        <div className="col-lg-2 col-md-2">
                            <div className="brand-img">
                                <img src={publicUrl + "assets/img/branding/burts-bees.png"} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-2 col-lg-2">
                            <div className="brand-img">
                                <img src={publicUrl + "assets/img/branding/harney.png"} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-2 col-lg-2">
                            <div className="brand-img">
                                <img src={publicUrl + "assets/img/branding/holabird.png"} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-2 col-lg-2">
                            <div className="brand-img">
                                <img src={publicUrl + "assets/img/branding/marvel.png"} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-2 col-lg-2">
                            <div className="brand-img">
                                <img src={publicUrl + "assets/img/branding/penske.png"} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-2 col-lg-2">
                            <div className="brand-img">
                                <img src={publicUrl + "assets/img/branding/shop-pbs.png"} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default BrandingSection;