import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CaseStudies extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <section className="policy-page-area pd-top-100 pd-bottom-100">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-8">
						<h4>Trust Seal Case Studies:</h4>
						<p>Here are some sample case studies of the benefits, and challenges, of using a Trust Seal on a website.</p>
						<p>The Case Studies are summarised below, and you can follow the link to see full details</p>
						<Link to="">CASE STUDY A:  10% Increase in Revenue</Link>
						<ul>
							<li>Study of 250,000 transactions at 500 online retailers</li>
							<li>14% increase in cart completion</li>
							<li>10% increase in revenue</li>
							<li>1 Trust seal is best (better than none or 2 seals)</li>
						</ul>
						<Link to="">CASE STUDY B. 16% Increase in Sales</Link>
						<ul>
							<li>16% increase in sales</li>
						</ul>
						<Link to="">CASE STUDY C: 28% Increase in Sales</Link>
						<ul>
							<li>8 week A/B split test study of Trust-Verified Seal on website</li>
							<li>28% more sales generated by Trust Seal during 8 week period</li>
							<li>115% more sales generated by Trust Seal during the last 3 weeks of this test (when more positive customer feedback had been received)</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	}
}

export default CaseStudies