import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FeaturedBottom extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="dmarket-area bg-sky-blue pd-top-90 pd-bottom-70">
			  <div className="container">
			    {/* <div className="row justify-content-center">
			      <div className="col-lg-5">
			        <div className="section-title text-center">
			          <h2>Earn certifications.
							Display trustmarks.
							Sell more.</h2>
			        </div>
			      </div>
			    </div> */}
			    <div className="row justify-content-center">
			      <div className="col-lg-7 col-md-4">
			         	<img src="/assets/img/branding/phone.png" width={500} height={700} alt="" />
			      </div> 
				  <div className="col-lg-5 mt">
			        <div className="section-titile">
			          <h2 className='mt-5'><span style={{color:"#80C500", fontSize:""}}> Earn certifications</span><br/>Display trustmarks. Sell more.</h2>
					  <p>Our process starts with certifying key issues like security, business integrity, and merchant reliability. As you earn our certifications, you can begin displaying them on your site with trustmarks.</p>
					  <p>Sites that use our trustmarks see conversion increases as high as 30%.</p>
					  <Link to="/certifications">Explore our Certifications →</Link>
			        </div>
				  </div>
			    </div>
			  </div>
			</section>


        }
}

export default FeaturedBottom