import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import FaqPage from './section-components/faq';
import Footer from './global-components/footer-v2';
import Policy from './section-components/policy';
import TrustSeal1 from './section-components/howtrustseal';
import WebsiteImportance from './section-components/websiteimport';
import CaseStudies from './section-components/casestudies';
import NavbarMain from './global-components/NavbarMain';

const CaseStudy = () => {
    return <div>
        <NavbarMain />
        <PageHeader headertitle="Trust Seal Case Studies" subheader="Trust Seal Case Studies"  />
        <CaseStudies/>
        <Footer />
    </div>
}

export default CaseStudy

