import React from 'react';
import { Link } from 'react-router-dom';

const TruesVerified = () => {
    return (
        <>
          <div className="trust-variefied pd-bottom-100">
            <div className="container">
                <div className="row">
                <p className='' style={{fontSize: "30px"}}>Display our Trust-Verified seal on your website to prove your credibility and legitimacy to your visitors, and increase your sales.</p>
                    <div className="col-lg-8 col-md-8">
                        <div className="trusted-content">
                        <div class="pull-left">
                            <ul class="well-ticks">
                                <li>Assure customers of your business identity and your privacy practices</li>
                                <li>3 month FREE trial – see the benefits yourself without any cost – no obligation</li>
                                <li>Collect customer feedback at time of sale, and 1-4 weeks after sale</li>
                                <li>Dispute Resolution</li>
                                <li>Quick &amp; Easy - join instantly online – no joining fees</li>
                                </ul> 
                            <a className="btn btn-base" href="/benefits-of-a-trust-seal/">Learn more</a> <Link className="btn btn-white" to="/signup">Join now</Link></div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <img src="https://trust-verified.org/seals/img/seal-pills-default.png" alt="" />
                         <div className='star'>
                            <img src="assets/img/icon/5star.png" alt="" />
                             <p className={{}}><span style={{fontWeight:"bold"}}>1M+</span>  Ratings</p>
                         </div>
                    </div>
                </div>
            </div>
          </div>  
        </>
    );
};

export default TruesVerified;